import {LDClient, LDMultiKindContext} from '@launchdarkly/node-server-sdk';
import type {Logger} from '@socialtables/logger';

export const PUREWEB_REGION_OVERRIDE = 'visualizer.pureweb-region-override';
export const RUNNING_VERSION_FLAG_NAME = 'visualizer.running-version';
export const STREAMING_PLATFORM_FLAG_NAME = 'visualizer.hosting';
export const PUREWEB_TARGET_HOST = 'visualizer.pureweb-target-host';
export const PUREWEB_MODEL_PROVIDER = 'visualizer.pureweb-model-provider';

/** Active feature flags for the front-end client */
export enum ClientFeatureKey {
  USE_AUTOMATIC_FAILOVER = 'visualizer.use-automatic-failover',
  SHOW_AI_EVENT_PLANNER = 'visualizer.show-ai-event-planner',
  WALKTHROUGH_ENABLED = 'walkthrough.enabled',
  USE_WL_RFP_BUTTON = 'visualizer.rfp-frontend',
  GAME_UI_OVERLAY = 'visualizer.use-web-ui-overlay',
}

export type UserProperties = {
  user?: StUser;
  custom: {
    [key: string]: string | number | boolean;
  };
};

export interface FeatureFlags {
  [PUREWEB_REGION_OVERRIDE]: string;
  [PUREWEB_TARGET_HOST]: string;
  [RUNNING_VERSION_FLAG_NAME]: string;
  [STREAMING_PLATFORM_FLAG_NAME]: string;
  [PUREWEB_MODEL_PROVIDER]: string;
}

export const DEFAULT_FLAGS: FeatureFlags = {
  [PUREWEB_REGION_OVERRIDE]: '',
  [RUNNING_VERSION_FLAG_NAME]: '',
  [PUREWEB_TARGET_HOST]: '',
  [STREAMING_PLATFORM_FLAG_NAME]: '',
  [PUREWEB_MODEL_PROVIDER]: '',
};

export class FlagFetcher {
  private readonly client: LDClient;
  private readonly logger: Logger;
  private readonly context: LDMultiKindContext;

  constructor(logger: Logger, client: LDClient, context: LDMultiKindContext) {
    this.logger = logger.child({module: 'launch-darkly'});
    this.context = context;
    this.client = client;

    this.logger.debug('Initialized LaunchDarkly fetcher');
  }

  async requestAllFlags(): Promise<FeatureFlags> {
    const allFlags = await this.client.allFlagsState(this.context);

    return allFlags.allValues() as FeatureFlags;
  }
}
